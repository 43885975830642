import './SubjectRepositorySurvey.less'

import { useLocation } from '@reach/router'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useEffect, useReducer, useState } from 'react'

import SurveyUnavailable from '../../../assets/images/survey-unavailable.svg'
import { useScopedIntl } from '../../../hooks'
import {
  AccountType,
  BackendError,
  CustomisationData,
  DateFormat,
  Feature,
  FileAnswer,
  QuestionType,
  SaveDeleteAnswerHandlers,
  SurveyAnswer,
  SurveyBlock,
  UiSettings,
  defaultMaxFileBytes,
  defaultStudyColor,
  deleteSubjectRepositoryFileAnswer,
  deleteSubjectRepositorySurveyAnswer,
  fetchCustomisationData as fetchCustomisationDataRequest,
  fetchSubjectRepositoryConditionalLogic as fetchSubjectRepositoryConditionalLogicRequest,
  fetchSubjectRepositoryFilesUrls,
  fetchSubjectRepositorySurvey as fetchSubjectRepositorySurveyRequest,
  fetchTranslatedLanguages as fetchTranslatedLanguagesRequest,
  saveSubjectRepositorySurveyAnswer,
  saveSubjectRepositorySurveyFileAnswer
} from '../../../requests'
import { routes } from '../../../routes'
import { isAnswerValueEmpty } from '../../../utils'
import { DatacLoading, DatacMessage } from '../../common'
import {
  Survey,
  SurveyContextConsumer,
  SurveyContextProvider,
  SurveyCounts,
  SurveyDataTypes,
  SurveyDetailsInfoItem,
  SurveySaveAnswerFn,
  SurveyThankYou,
  surveyDataInitialState,
  surveyDataReducer
} from '../../shared/Survey'

const ExpiredSubjectRepositoryPage: React.FC = () => {
  const intlSubjectRepository = useScopedIntl('subject_repository.survey.expired')
  return (
    <div className="subject-repository-survey-expired">
      <SurveyUnavailable />
      <p>{intlSubjectRepository('label')}</p>
      <p>{intlSubjectRepository('content')}</p>
    </div>
  )
}

export const SubjectRepositorySurvey: React.FC = () => {
  const intl = useScopedIntl('')
  const intlSurvey = useScopedIntl('survey')
  const location = useLocation()
  const subjectId = location.hash.slice(1)
  const intlSubjectRepository = useScopedIntl('subject_repository.survey')
  const [loading, setLoading] = useState(true)
  const [isExpired, setIsExpired] = useState(false)
  const [surveyData, surveyDataDispatch] = useReducer(surveyDataReducer, surveyDataInitialState)
  const [uiSettings, setUiSettings] = useState<UiSettings>(null)
  const [isLoadingCustomisation, setIsLoadingCustomisation] = useState(false)

  useEffect(() => {
    fetchCustomisationData()
    fetchSubjectRepositorySurvey()
    fetchTranslatedLanguages()
  }, [])

  const fetchCustomisationData = () => {
    setIsLoadingCustomisation(true)
    fetchCustomisationDataRequest(true, {
      onSuccess: (data: CustomisationData) => {
        setIsLoadingCustomisation(false)
        setUiSettings({
          studyId: '',
          logoUrl: data.logoUrl || '',
          mainColor: data.mainColor || defaultStudyColor,
          buttonColor: data.buttonColor || defaultStudyColor,
          maxFileBytes: defaultMaxFileBytes,
          dateFormat: DateFormat.CA
        })
      },
      onRequestError: code => {
        DatacMessage.genericError(intl, code)
        setIsLoadingCustomisation(false)
      }
    })
  }

  const fetchSubjectRepositorySurvey = () => {
    setLoading(true)
    fetchSubjectRepositorySurveyRequest(
      { subjectId },
      {
        onSuccess: surveyData => {
          surveyDataDispatch({ type: SurveyDataTypes.SET, survey: surveyData })
          fetchSubjectRepositoryConditionalLogic()
        },
        onNotFound: () => {
          navigate(routes.notFound(AccountType.Subject))
        },
        onExpired: () => {
          setIsExpired(true)
          setLoading(false)
        },
        onUnauthorized: () => {
          navigate(routes.signIn(AccountType.Subject))
        },
        onRequestError: () => {
          DatacMessage.error(
            intlSubjectRepository('unknown_error.title'),
            intlSubjectRepository('unknown_error.content')
          )
        }
      }
    )
  }

  const fetchSubjectRepositoryConditionalLogic = () => {
    fetchSubjectRepositoryConditionalLogicRequest(
      {},
      {
        onSuccess: conditionalRules => {
          surveyDataDispatch({ type: SurveyDataTypes.SET_CONDITIONAL_RULES, conditionalRules })
          setLoading(false)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const fetchTranslatedLanguages = () => {
    fetchTranslatedLanguagesRequest(
      {},
      {
        onSuccess: languages => setUiSettings(uiSettings => ({ ...uiSettings, languages })),
        onRequestError: () => DatacMessage.error(intlSubjectRepository('unknown_error.title'))
      }
    )
  }

  const detailsBoxes = (surveyCounts: SurveyCounts, questionsConditionFiltered: SurveyBlock[]) => (
    <>
      <SurveyDetailsInfoItem
        icon="helpCircle"
        title={intlSurvey('details.information.questions')}
        value={questionsConditionFiltered.length}
        hideOnMobile
        color={uiSettings?.mainColor || defaultStudyColor}
      />
      <SurveyDetailsInfoItem
        icon="checkCircle"
        title={intlSurvey('details.information.responses')}
        value={surveyCounts.fulfilled}
        hideOnMobile
        color={uiSettings?.mainColor || defaultStudyColor}
      />
    </>
  )

  const saveAnswer: SurveySaveAnswerFn = (answer, question) => {
    return new Promise((resolve, reject) => {
      const handlers = {
        onSuccess: (response?: SurveyAnswer) => {
          if (response) {
            surveyDataDispatch({ type: SurveyDataTypes.UPDATE_ANSWER, answer: response })
            if (response.validationMessages.length) reject()
          }
          resolve()
        },
        onDeleteSuccess: () => {
          const answerId = surveyData.answers.find((a: SurveyAnswer) => a.questionId === question.id)?.id
          surveyDataDispatch({
            type: SurveyDataTypes.REMOVE_ANSWER,
            id: answerId
          })
          resolve()
        },
        onRequestError: (code: number) => {
          const questionPrefix =
            code && code === BackendError.EPRO_STATUS_PUBLISHED ? 'no_question_error' : 'save_error'

          DatacMessage.error(
            intlSurvey(`answer.${questionPrefix}.title`),
            intlSurvey(`answer.${questionPrefix}.content`)
          )
          reject()
        }
      }

      const uploadFileHandlers = {
        onFileSizeExceeded: () => {
          DatacMessage.warning(intl('file_upload.error.title'), intl('api.9002'))
          reject()
        },
        onWrongFileFormat: () => {
          DatacMessage.warning(intl('file_upload.error.title'), intl('api.9007'))
          reject()
        }
      }

      const commonParams = {
        questionId: question.id,
        subjectId: surveyData?.subjectId
      }

      const deleteFn = (handlers: SaveDeleteAnswerHandlers) => {
        if (question.type === QuestionType.File) {
          const currentAnswer = surveyData.answers.find(a => a.questionId === question.id)
          deleteSubjectRepositoryFileAnswer({ fileId: currentAnswer.value[0].id }, handlers, {
            subjectId: surveyData?.subjectId
          })
        } else {
          deleteSubjectRepositorySurveyAnswer(commonParams, handlers)
        }
      }

      const saveFn = (handlers: SaveDeleteAnswerHandlers) =>
        question.type === QuestionType.File
          ? saveSubjectRepositorySurveyFileAnswer(
              { ...commonParams, fileId: (answer as FileAnswer[])?.[0].id },
              { ...handlers, ...uploadFileHandlers }
            )
          : saveSubjectRepositorySurveyAnswer({ ...commonParams, answer }, handlers)

      // File only: if we need to replace a file, first delete and then send new one
      if (question.type === QuestionType.File && surveyData.answers.find(a => a.questionId === question.id)?.value) {
        deleteFn({
          onSuccess: () =>
            isAnswerValueEmpty(question.type, answer)
              ? handlers.onDeleteSuccess()
              : saveFn({ onSuccess: handlers.onSuccess, onRequestError: handlers.onRequestError }),
          onRequestError: handlers.onRequestError
        })
        return
      }

      if (isAnswerValueEmpty(question.type, answer)) {
        deleteFn({ onSuccess: handlers.onDeleteSuccess, onRequestError: handlers.onRequestError })
      } else {
        saveFn({ onSuccess: handlers.onSuccess, onRequestError: handlers.onRequestError })
      }
    })
  }

  return (
    <div className="subject-repository-survey">
      <DatacLoading isLoading={loading || isLoadingCustomisation}>
        {isExpired ? (
          <ExpiredSubjectRepositoryPage />
        ) : (
          <SurveyContextProvider
            subjectId={surveyData?.subjectId}
            blocks={surveyData?.blocks}
            answers={surveyData?.answers}
            onFileUrlFetch={fetchSubjectRepositoryFilesUrls()}
            titleLabel={intlSurvey('details.name_label')}
            token={null}
            uiSettings={uiSettings}
          >
            <SurveyContextConsumer>
              {({ surveyCounts, filteredQuestions }) => (
                <Survey
                  name=""
                  endPages={[
                    {
                      component: <SurveyThankYou feature={Feature.SubjectRepository} />,
                      nextName: intlSubjectRepository('go_to_dashboard'),
                      nextRedirectRoute: routes.subjectDashboard
                    }
                  ]}
                  onAnswerSave={saveAnswer}
                  detailsBoxes={detailsBoxes(surveyCounts, filteredQuestions)}
                />
              )}
            </SurveyContextConsumer>
          </SurveyContextProvider>
        )}
      </DatacLoading>
    </div>
  )
}
